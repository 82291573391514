import * as React from "react"
import PageLayout from "../../components/pageLayout";
import Seo from "../../components/seo";

const GrunnundersokelserPage = () => {
    // const {
    //     kraftlinje,
    //     rorgate,
    //     vindmolle,
    //     bygninger,
    //     // data
    // } = useStaticQuery(graphql`
    //     {
    //         kraftlinje: file(relativePath: { eq: "inspeksjon/linjeinspeksjon.jpg" }) {
    //             childImageSharp {
    //                 gatsbyImageData
    //             }
    //         },
    //         rorgate: file(relativePath: { eq: "inspeksjon/rorgate.jpg" }) {
    //             childImageSharp {
    //                 gatsbyImageData
    //             }
    //         },
    //         vindmolle: file(relativePath: { eq: "inspeksjon/vindmolle.jpeg" }) {
    //             childImageSharp {
    //                 gatsbyImageData
    //             }
    //         },
    //         bygninger: file(relativePath: { eq: "inspeksjon/bygninger.jpeg" }) {
    //             childImageSharp {
    //                 gatsbyImageData
    //             }
    //         },
    //         #            data: markdownRemark(frontmatter: {slug: {eq: "bransjer/landbruk"}}) {
    //         #                id
    //         #                frontmatter {
    //         #                    subTitle
    //         #                    title
    //         #                    bruksomrader
    //         #                    girdegbedre
    //         #                }
    //         #                html
    //         #            },
    //     }
    // `)

    return <PageLayout>
        <Seo title={"Grunnundersøkelser"}/>
        <div className="relative py-16 bg-secondary-light overflow-hidden">
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <h1>
            <span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">
              Tjenester:
            </span>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl">
              Grunnundersøkelser
            </span>
                    </h1>
                </div>
                <div className="mt-6 prose prose-primary prose-lg text-gray-200 mx-auto">
                    <p>
                        Bakkeradar er en geofysisk undersøkelses metode basert på hastigheten til elektromagnetiske bølger. En energipuls blir sendt ut. Refleksjoner blir målt i tid (ns)
                        Refleksjoner oppstår ved grenseflater med ulike egenskaper.
                        Målinger i tid gir som oftest liten mening, og konverteres derfor til dybde. Vi leverer kartlegginger med oppløsning ned til 2 cm, og kan detektere objekter og lag ned til 40 meter i fast fjell.
                        <br />
                        Eksempler på bruk av bakkeradar:
                    </p>
                    <ul>
                        <li>Komplett kartlegging over og under jordoverflaten</li>
                        <li>Dybde kartlegging av grunnfjell, myr, vann, elver, snø.</li>
                        <li>Kartlegging av tykkelse på lagstrukturer</li>
                        <li>Lokalisering av nedgravde objekter som; rør, hulrom, kabler, tanker, etc.</li>
                        <li>Arkeologiske undersøkelser</li>
                    </ul>
                </div>
            </div>
        </div>
        {/*<div className="relative bg-secondary-light pt-8 pb-32 overflow-hidden">*/}
        {/*    <div className="relative">*/}
        {/*        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">*/}
        {/*            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">*/}
        {/*                <div>*/}
        {/*                    <div className="mt-6">*/}
        {/*                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">*/}
        {/*                            Komplett kartlegging over og under jordoverflaten*/}
        {/*                        </h2>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className="mt-12 sm:mt-16 lg:mt-0">*/}
        {/*                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">*/}
        {/*                    <GatsbyImage*/}
        {/*                        className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"*/}
        {/*                        image={kraftlinje.childImageSharp.gatsbyImageData}*/}
        {/*                    />*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div className="mt-24">*/}
        {/*        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">*/}
        {/*            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">*/}
        {/*                <div>*/}
        {/*                    <div className="mt-6">*/}
        {/*                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">*/}
        {/*                            Dybde kartlegging av grunnfjell, myr, vann, elver, snø*/}
        {/*                        </h2>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">*/}
        {/*                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">*/}
        {/*                    <GatsbyImage*/}
        {/*                        className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"*/}
        {/*                        image={vindmolle.childImageSharp.gatsbyImageData}*/}
        {/*                    />*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div className="relative mt-24">*/}
        {/*        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">*/}
        {/*            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">*/}
        {/*                <div>*/}
        {/*                    <div className="mt-6">*/}
        {/*                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">*/}
        {/*                            Kartlegging av tykkelse på lagstrukturer*/}
        {/*                        </h2>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className="mt-12 sm:mt-16 lg:mt-0">*/}
        {/*                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">*/}
        {/*                    <GatsbyImage*/}
        {/*                        className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"*/}
        {/*                        image={rorgate.childImageSharp.gatsbyImageData}*/}
        {/*                    />*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div className="mt-24">*/}
        {/*        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">*/}
        {/*            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">*/}
        {/*                <div>*/}
        {/*                    <div className="mt-6">*/}
        {/*                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">*/}
        {/*                            Lokalisering av nedgravde objekter som; rør, hulrom, kabler, tanker, etc.*/}
        {/*                        </h2>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">*/}
        {/*                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">*/}
        {/*                    <GatsbyImage*/}
        {/*                        className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"*/}
        {/*                        image={bygninger.childImageSharp.gatsbyImageData}*/}
        {/*                    />*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div className="relative mt-24">*/}
        {/*        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">*/}
        {/*            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">*/}
        {/*                <div>*/}
        {/*                    <div className="mt-6">*/}
        {/*                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-100">*/}
        {/*                            Arkeologiske undersøkelser*/}
        {/*                        </h2>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className="mt-12 sm:mt-16 lg:mt-0">*/}
        {/*                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">*/}
        {/*                    <GatsbyImage*/}
        {/*                        className="w-full rounded-xl max-h-72 lg:max-h-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"*/}
        {/*                        image={rorgate.childImageSharp.gatsbyImageData}*/}
        {/*                    />*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </PageLayout>
}

export default GrunnundersokelserPage